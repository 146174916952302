import React from "react";
import { Link } from "react-router-dom";

// import ContactIcons from "../Contact/ContactIcons";

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/MagiTech.webp`} alt="" />
      </Link>
      <header>
        <h2>MagiTech</h2>
        <p>
          <a href="mailto:MagiTech.site">MagiTech.site</a>
        </p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>
        At MagiTech Solutions, we believe that the future is not just about what
        technology can do, but also about the magic it can bring to everyday
        life. Let us enchant you with our innovative products and services.
      </p>
      <ul className="actions">
        <li>
          <Link to="https://itsDigvijaysing.github.io" className="button">
            About Me
          </Link>
        </li>
      </ul>
    </section>

    {/* <section id="footer">
      <ContactIcons />
      <p className="copyright">
        MagiTech <Link to="/">MagiTech.site</Link>.
      </p>
    </section> */}
  </section>
);

export default SideBar;
